<template lang="pug">
  .hero__container
    h1.title.hero__title
      | Новий рівень мобільності з
      span Drivelink
    .hero__actions
      router-link(
        to=""
        v-scroll-to="'#steps5'"
      ).link.hero__link ЗАЛИШИТИ ЗАЯВКУ
      a(href="tel:+380630203007").link.hero__link.hero__link--tel подзвонити
    .hero__img
      img(
        :src="HeroImg"
        alt="auto"
      )
</template>

<script>
import HeroImg from '@/assets/images/hero-img.png'

export default {
  name: 'HomeBanner',
  data () {
    return {
      HeroImg
    }
  }
}
</script>
